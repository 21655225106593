import React from "react";
import ReactDOM from "react-dom/client";
import "react-tooltip/dist/react-tooltip.css";

import Authenticator from "./Components/Authentication/Authenticator";
import SessionProvider from "./Contexts/Session/SessionContext";
import UserDetailsProvider from "./Contexts/UserDetailsContext/UserDetailsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SessionProvider>
      <UserDetailsProvider>
        <Authenticator />
      </UserDetailsProvider>
    </SessionProvider>
  </React.StrictMode>
);
