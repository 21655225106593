// enum values which match widgetitemtype enum in PSC for module template
enum RmsItemsEnum {
  RMS_WORKLOAD = 10,
  RMS_SURGERY_DROP_OFF = 11,
  RMS_READY_FOR_DELIVERY = 12,
  RMS_SURGERY_COLLECTION = 13,
  RMS_COLLECTION = 14
}

export default RmsItemsEnum;
