import { useEffect, useState, useContext, useRef } from "react";

import { Tooltip } from "react-tooltip";
import { Card } from "@emisgroup/ui-card";
import Refresh from "~icons/ic/outline-refresh";
import { Badge } from "@emisgroup/ui-badge";
import WidgetItems from "../../WidgetItems/Index";
import { IModifiedWidgetItem, WidgetContext, WidgetContextValue } from "../../../Contexts/Widget/WidgetContext";
import { useValidateWidgetItems } from "../../../CustomHooks/UseValidateWidgetItems";
import { WidgetItemsProps } from "../../WidgetItems/WidgetItems";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import ManageWidgetItems from "../../ManageWidgetItems/ManageWidgetItems";
import WidgetEnum from "../../../Enum/WidgetEnum";
import { WidgetContentEnum } from "../../../Enum/WidgetContentEnum";
import useHandleRefresh from "../../../CustomHooks/UseHandleRefresh";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import WidgetErrorContent from "../../WidgetErrorContent/WidgetErrorContent";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { today } from "../../../Utilities/DateHelper";
import WidgetItemsEnum from "../../../Enum/WidgetItemsEnum";
import PendingItemsEnum from "../../../Enum/PendingItemsEnum";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import { DispatchModuleTemplateEvent } from "../../../Utilities/ModuleTemplate/Index";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { FormatDate } from "../../../Utilities/FormatDate";

import "../WidgetCard.scss";

const PendingCard = (props: { refreshIntervalSecond: number }) => {
  const { widgetItem, widget, searchWidgetText, isSearchAvailable } = useContext<WidgetContextValue>(WidgetContext);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const pendingItem: IModifiedWidgetItem = widgetItem?.find((item) => item?.widgetName == WidgetEnum.PENDING);
  const [pendingWidgetItems, setPendingWidgetItems] = useState(undefined);
  const filteredPendingItems = useValidateWidgetItems(WidgetEnum.PENDING, pendingItem?.widgetItem);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "info",
    onClose: () => {}
  });
  const [pendingContentState, setPendingContentState] = useState(WidgetContentEnum.PROGRESS_SPINNER);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const { setRefreshCall } = useHandleRefresh();
  const pendingRefreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const pendingIntervalStartTime = useRef<number>();
  const elapsedTime = useRef<number>(0);
  let isManualRefresh: boolean = false;
  const [pendingItemCount, setPendingItemCount] = useState(0);
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(time);
  const [formatDate, setFormatDate] = useState(formattedDate);

  useEffect(() => {
    setPendingWidgetItems(filteredPendingItems);

    let pendingCount = 0;

    filteredPendingItems?.forEach((element) => {
      if (element.attributes.enabled) pendingCount += element.attributes.count;
    });

    setPendingItemCount(pendingCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItem]);

  // useEffect for auto refresh
  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = props.refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      pendingRefreshIntervalRef.current = setTimeout(() => {
        HandlePendingRefreshClick(false);
        adjustedRefreshInterval = props.refreshIntervalSecond;
        pendingIntervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(pendingRefreshIntervalRef.current);
    };

    if (!isDialogOpen) {
      pendingIntervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const pendingIntervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (pendingIntervalStopTime - pendingIntervalStartTime.current) / 1000;
      adjustedRefreshInterval = props.refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(pendingRefreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, props.refreshIntervalSecond]);

  useEffect(() => {
    if (pendingWidgetItems?.length === 0 || !pendingWidgetItems?.some((i) => i.attributes.enabled))
      return setPendingContentState(WidgetContentEnum.NO_CONTENT);

    setPendingContentState(WidgetContentEnum.VALID_CONTENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingWidgetItems?.length, pendingWidgetItems]);

  useEffect(() => {
    if (WidgetEnum.PENDING.toLowerCase() === searchWidgetText) {
      document.getElementById("pending-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const HandlePendingRefreshClick = (showErrorNotification: boolean) => {
    isManualRefresh = showErrorNotification;
    setRefreshCall({
      isApiCalled: true,
      showErrorNotification: showErrorNotification,
      widgetId: widget.find((i) => i.attributes.name == WidgetEnum.PENDING).id,
      HandleWidgetApiFailure: HandlePendingWidgetApifailure,
      HandleSuccessNotification: HandlePendingSuccessNotification
    });
  };

  const HandlePendingSuccessNotification = () => {
    const [refreshedTime, refreshedFormattedDate] = FormatDate();
    setFormatDate(refreshedFormattedDate);
    setLastRefreshedTime(refreshedTime);

    if (!isManualRefresh) return;

    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Pending widget refreshed ", text: "content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const HandlePendingWidgetApifailure = () => {
    setPendingContentState(WidgetContentEnum.ERROR_CONTENT);
    setPendingItemCount(0);
  };

  const TriggerPendingModule = (e) => {
    const selectedItem =
      e.target.value == WidgetItemsEnum.SDM_INSTALLMENTS
        ? ModuleTemplateEnum.SDM_INSTALLMENTS
        : ModuleTemplateEnum.NMS_MANAGER;

    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === selectedItem;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        content: NotificationContent.error,
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    let pendingReplacements = GetReplacements(e.target.value);

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module, pendingReplacements);
  };

  const GetReplacements = (item: any) => {
    switch (item) {
      case WidgetItemsEnum.NMS_ENGAGEMENT_PENDING:
        return {
          "##DATE##": JSON.stringify(today),
          "##NMSPending##": PendingItemsEnum.NMS_ENGAGEMENT_PENDING
        };

      case WidgetItemsEnum.NMS_INTERVENTION_PENDING:
        return {
          "##DATE##": JSON.stringify(today),
          "##NMSPending##": PendingItemsEnum.NMS_INTERVENTION_PENDING
        };

      case WidgetItemsEnum.NMS_FOLLOW_UP_PENDING:
        return {
          "##DATE##": JSON.stringify(today),
          "##NMSPending##": PendingItemsEnum.NMS_FOLLOW_UP_PENDING
        };

      case WidgetItemsEnum.SDM_INSTALLMENTS:
        return {
          "##DATE##": JSON.stringify(today)
        };
    }
  };

  const widgetItemsProps: WidgetItemsProps = {
    items: pendingWidgetItems,
    listItemOnClick: (e) => {
      TriggerPendingModule(e);
    }
  };

  let pendingContent = null;

  switch (pendingContentState) {
    case WidgetContentEnum.VALID_CONTENT:
      pendingContent = <WidgetItems {...widgetItemsProps} />;
      break;

    case WidgetContentEnum.NO_CONTENT:
      pendingContent = (
        <p data-dd-action-name="no-action-text" id="empty-script" className="empty-widget-item">
          No items have been configured for this widget
        </p>
      );
      break;

    default:
      pendingContent = (
        <ProgressSpinner className="spinner" data-testid="pending-progress-spinner" text={"Loading"} delay={1000} />
      );
      break;
  }

  return (
    <div className="pending-container" data-testid="pending">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="pending-card"
        className={`widget-card ${WidgetEnum.PENDING.toLowerCase() === searchWidgetText ? "widget-highlight" : ""}`}
        data-testid="pending-card"
        id="pending-card"
      >
        <div className="widget-header-container">
          <span className="widget-header-text">
            Pending <Badge>{pendingItemCount || String(0)}</Badge>
          </span>

          <div className="widget-header-button-container">
            <span data-dd-action-name="no-action-text" className="last-refreshed-time">
              {lastRefreshedTime}
            </span>
            <button
              data-dd-action-name="pending-refresh"
              className="widget-refresh-button"
              data-testid="pending-refresh-button"
              data-tooltip-id="pending-refresh-tooltip"
              data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
              onClick={() => {
                HandlePendingRefreshClick(true);
              }}
            >
              <Tooltip id="pending-refresh-tooltip" className="tooltip" data-testid="pending-refresh-tooltip" />
              <Refresh
                title=""
                className="widget-header-refresh-icon"
                data-testid="refresh-icon"
                size="medium"
                color="	var(--neutral-50)"
              ></Refresh>
            </button>

            <ManageWidgetItems widgetItems={pendingWidgetItems} widgetName={WidgetEnum.PENDING} />
          </div>
        </div>
        {pendingContentState === WidgetContentEnum.ERROR_CONTENT ? (
          <WidgetErrorContent />
        ) : (
          <div className="widget-item-container" data-testid="pending-list-item-container">
            <span>{pendingContent}</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default PendingCard;
