import { useEffect, useState } from "react";

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  let adjustedDelay = typeof value === "string" && value.length >= 3 ? 300 : delay;

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), adjustedDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay, adjustedDelay]);

  return debouncedValue;
}
