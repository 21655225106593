import EmptyFolderTwoTone from "~icons/ic/twotone-folder-off";

import "./EmptyWidget.scss";

export const EmptyWidget = () => {
  return (
    <div className="empty-widgets" data-testid="empty-widget-container">
      <EmptyFolderTwoTone title="" className="btn" />
      <h3 data-dd-action-name="no-action-text" className="font-head">
        No active widgets
      </h3>
      <div data-dd-action-name="no-action-text" className="text-field">
        It looks like there are no active widgets to be displayed here
      </div>
    </div>
  );
};
