import "./WidgetErrorContent.scss";

const WidgetErrorContent = () => {
  return (
    <div data-dd-action-name="no-action-text" className="error-content-widget">
      <p>Unable to retrieve widget data</p>
    </div>
  );
};

export default WidgetErrorContent;
