import { useEffect, useState, useContext, useRef } from "react";

import { Tooltip } from "react-tooltip";
import { Card } from "@emisgroup/ui-card";
import Refresh from "~icons/ic/outline-refresh";
import { Badge } from "@emisgroup/ui-badge";
import WidgetItems from "../../WidgetItems/Index";
import { IModifiedWidgetItem, WidgetContext, WidgetContextValue } from "../../../Contexts/Widget/WidgetContext";
import { useValidateWidgetItems } from "../../../CustomHooks/UseValidateWidgetItems";
import { WidgetItemsProps } from "../../WidgetItems/WidgetItems";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import ManageWidgetItems from "../../ManageWidgetItems/ManageWidgetItems";
import WidgetEnum from "../../../Enum/WidgetEnum";
import { WidgetContentEnum } from "../../../Enum/WidgetContentEnum";
import useHandleRefresh from "../../../CustomHooks/UseHandleRefresh";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import WidgetErrorContent from "../../WidgetErrorContent/WidgetErrorContent";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import WidgetItemsEnum from "../../../Enum/WidgetItemsEnum";
import RmsItemsEnum from "../../../Enum/RmsItemsEnum";
import { today } from "../../../Utilities/DateHelper";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { DispatchModuleTemplateEvent, ModuleTemplateEnum } from "../../../Utilities/ModuleTemplate/Index";
import { FormatDate } from "../../../Utilities/FormatDate";

import "../WidgetCard.scss";

const RmsCard = (props: { refreshIntervalSecond: number }) => {
  const { widgetItem, widget, searchWidgetText, isSearchAvailable } = useContext<WidgetContextValue>(WidgetContext);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const rmsItem: IModifiedWidgetItem = widgetItem?.find((item) => item?.widgetName == WidgetEnum.RMS);
  const [rmsWidgetItems, setRmsWidgetItems] = useState(undefined);
  const filteredRmsItems = useValidateWidgetItems(WidgetEnum.RMS, rmsItem?.widgetItem);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "info",
    onClose: () => {}
  });
  const [rmsContentState, setRmsContentState] = useState(WidgetContentEnum.PROGRESS_SPINNER);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const { setRefreshCall } = useHandleRefresh();
  const rmsRefreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const rmsIntervalStartTime = useRef<number>();
  const elapsedTime = useRef<number>(0);
  let isManualRefresh: boolean = false;
  const [rmsItemCount, setRmsItemCount] = useState(0);
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(time);
  const [formatDate, setFormatDate] = useState(formattedDate);

  useEffect(() => {
    setRmsWidgetItems(filteredRmsItems);
    let rmsCount = 0;

    filteredRmsItems?.forEach((element) => {
      if (element.attributes.enabled) rmsCount += element.attributes.count;
    });

    setRmsItemCount(rmsCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItem]);

  // useEffect for auto refresh
  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = props.refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      rmsRefreshIntervalRef.current = setTimeout(() => {
        HandleRmsRefreshClick(false);
        adjustedRefreshInterval = props.refreshIntervalSecond;
        rmsIntervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(rmsRefreshIntervalRef.current);
    };

    if (!isDialogOpen) {
      rmsIntervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const rmsIntervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (rmsIntervalStopTime - rmsIntervalStartTime.current) / 1000;
      adjustedRefreshInterval = props.refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(rmsRefreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, props.refreshIntervalSecond]);

  useEffect(() => {
    if (rmsWidgetItems?.length === 0 || !rmsWidgetItems?.some((i) => i.attributes.enabled))
      return setRmsContentState(WidgetContentEnum.NO_CONTENT);
    setRmsContentState(WidgetContentEnum.VALID_CONTENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rmsWidgetItems?.length, rmsWidgetItems]);

  useEffect(() => {
    if (WidgetEnum.RMS.toLowerCase() === searchWidgetText) {
      document.getElementById("rms-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const HandleRmsRefreshClick = (showErrorNotification: boolean) => {
    isManualRefresh = showErrorNotification;
    setRefreshCall({
      isApiCalled: true,
      showErrorNotification: showErrorNotification,
      widgetId: widget.find((i) => i.attributes.name == WidgetEnum.RMS).id,
      HandleWidgetApiFailure: HandleRmsWidgetApifailure,
      HandleSuccessNotification: HandleRmsSuccessNotification
    });
  };

  const HandleRmsSuccessNotification = () => {
    const [refreshedTime, refreshedFormattedDate] = FormatDate();
    setFormatDate(refreshedFormattedDate);
    setLastRefreshedTime(refreshedTime);

    if (!isManualRefresh) return;

    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Rms widget refreshed ", text: "content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const HandleRmsWidgetApifailure = () => {
    setRmsContentState(WidgetContentEnum.ERROR_CONTENT);
    setRmsItemCount(0);
  };

  const TriggerRmsModule = (e: { target: { value: any } }) => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.RMS;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        content: NotificationContent.error,
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    let rmsReplacements = {
      "##DATE##": JSON.stringify(today),
      selectedWidgetItem: GetSelectedWidgetItem(e.target.value)
    };

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module, rmsReplacements);
  };

  const GetSelectedWidgetItem = (item: any) => {
    switch (item) {
      case WidgetItemsEnum.WORKLOAD:
        return RmsItemsEnum.RMS_WORKLOAD;
      case WidgetItemsEnum.SURGERY_DROP_OFF:
        return RmsItemsEnum.RMS_SURGERY_DROP_OFF;
      case WidgetItemsEnum.SURGERY_COLLECTION:
        return RmsItemsEnum.RMS_SURGERY_COLLECTION;
      case WidgetItemsEnum.READY_FOR_DELIVERY:
        return RmsItemsEnum.RMS_READY_FOR_DELIVERY;
      case WidgetItemsEnum.COLLECTION:
        return RmsItemsEnum.RMS_COLLECTION;
    }
  };

  const widgetItemsProps: WidgetItemsProps = {
    items: rmsWidgetItems,
    listItemOnClick: (e) => {
      TriggerRmsModule(e);
    }
  };

  let rmsContent = null;

  switch (rmsContentState) {
    case WidgetContentEnum.VALID_CONTENT:
      rmsContent = <WidgetItems {...widgetItemsProps} />;
      break;

    case WidgetContentEnum.NO_CONTENT:
      rmsContent = (
        <p data-dd-action-name="no-action-text" id="empty-script" className="empty-widget-item">
          No items have been configured for this widget
        </p>
      );
      break;

    default:
      rmsContent = (
        <ProgressSpinner className="spinner" data-testid="rms-progress-spinner" text={"Loading"} delay={1000} />
      );
      break;
  }

  return (
    <div className="rms-container" data-testid="rms">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="rms-card"
        className={`widget-card ${WidgetEnum.RMS.toLowerCase() === searchWidgetText ? "widget-highlight" : ""}`}
        data-testid="rms-card"
        id="rms-card"
      >
        <div className="widget-header-container">
          <span className="widget-header-text">
            RMS <Badge>{rmsItemCount || String(0)}</Badge>
          </span>

          <div className="widget-header-button-container">
            <span data-dd-action-name="no-action-text" className="last-refreshed-time">
              {lastRefreshedTime}
            </span>
            <button
              data-dd-action-name="rms-refresh"
              className="widget-refresh-button"
              data-testid="rms-refresh-button"
              data-tooltip-id="rms-refresh-tooltip"
              data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
              onClick={() => {
                HandleRmsRefreshClick(true);
              }}
            >
              <Tooltip id="rms-refresh-tooltip" className="tooltip" data-testid="rms-refresh-tooltip" />
              <Refresh
                title=""
                className="widget-header-refresh-icon"
                data-testid="refresh-icon"
                size="medium"
                color="	var(--neutral-50)"
              ></Refresh>
            </button>

            <ManageWidgetItems widgetItems={rmsWidgetItems} widgetName={WidgetEnum.RMS} />
          </div>
        </div>
        {rmsContentState === WidgetContentEnum.ERROR_CONTENT ? (
          <WidgetErrorContent />
        ) : (
          <div className="widget-item-container" data-testid="rms-list-item-container">
            <span>{rmsContent}</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default RmsCard;
