import ErrorCircleOutline from "~icons/ic/outline-error-outline";

const AuthenticatedError = () => {
  return (
    <div className="error-card">
      <div className="error-header">
        <ErrorCircleOutline size="small" title="" className="error-header-icon" />
        <div className="error-header-text">Sorry, there's been a problem</div>
      </div>
      <div className="error-content">
        This page can't be loaded as we have been unable to authenticate with your user account.
        <br />
        <br />
        <strong data-testid="error-resolution">To resolve this please sign out and back in again</strong>
      </div>
    </div>
  );
};

export default AuthenticatedError;
