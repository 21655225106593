import { useEffect } from "react";

import { InitalizeDatadog } from "./Utilities/Datadog";
import Layout from "./Components/Layout/Index";

import "./App.scss";

const App = () => {
  useEffect(() => {
    InitalizeDatadog();
  }, []);
  return <Layout />;
};

export default App;
