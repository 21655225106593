import { useContext } from "react";

import { IFeedItems } from "../Components/Footer/Footer";
import { SessionContext, SessionContextValue } from "../Contexts/Session/SessionContext";
import { UserDetailsContext, UserDetailsContextValue } from "../Contexts/UserDetailsContext/UserDetailsContext";
import ISession from "../Contexts/Session/ISession";
import IUserDetails from "../Contexts/UserDetailsContext/IUserDetails";
import { AuthorizeWidget } from "../Components/AuthorizeWidget/AuthorizeWidget";
import ActivityEnum from "../Enum/ActivityEnum";
import ProScriptConnectModule from "../Enum/ProScriptConnectModule";
import FooterItemsEnum from "../Enum/FooterItemsEnum";

export const useValidateFooterItems = (feedItems: IFeedItems[]) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  let enabledFeedItems = ValidateFeedItems(session, userDetails, feedItems);
  let filteredFeedItems = enabledFeedItems?.filter((i: IFeedItems) => typeof i !== "undefined");
  return filteredFeedItems;
};

const ValidateFeedItems = (session: ISession, userDetails: IUserDetails, feedItems?: IFeedItems[]) => {
  const footerItemsCheck = (item: IFeedItems, activityId: number, moduleName?: ProScriptConnectModule) => {
    if (
      AuthorizeWidget({
        validationDetails: {
          activityId: activityId,
          moduleName: [moduleName]
        },
        contextDetails: { userDetails, session }
      })
    )
      return item;
  };

  return feedItems?.map((item: IFeedItems) => {
    switch (item?.attributes.modulename) {
      case FooterItemsEnum.NMS:
        item = footerItemsCheck(item, ActivityEnum.ACCESS_NMS, ProScriptConnectModule.NMS_MANAGER);
        return item;

      case FooterItemsEnum.PRESCRIPTION_AND_ITEM_PROCESSED:
        item = footerItemsCheck(
          item,
          ActivityEnum.ACCESS_HOMEPAGE,
          ProScriptConnectModule.PRESCRIPTION_AND_ITEM_PROCESSED
        );
        return item;

      case FooterItemsEnum.EMERGENCY_SUPPLY_OVERDUE:
        item = footerItemsCheck(item, ActivityEnum.EMERGENCY_SUPPLY_OVERDUE, ProScriptConnectModule.OVERDUE_SCRIPTS);
        return item;

      case FooterItemsEnum.MDS:
        item = footerItemsCheck(item, ActivityEnum.EMERGENCY_SUPPLY_OVERDUE, ProScriptConnectModule.MDS);
        return item;

      case FooterItemsEnum.DORMANT_OWING:
      case FooterItemsEnum.OWING:
        item = footerItemsCheck(item, ActivityEnum.OWINGS, ProScriptConnectModule.OWINGS);
        return item;

      case FooterItemsEnum.REPEAT_PRIVATE_PRESCRIPTION:
        item = footerItemsCheck(
          item,
          ActivityEnum.REPEAT_PRIVATE_PRESCRIPTION,
          ProScriptConnectModule.REPEAT_PRIVATE_PRESCRIPTION
        );
        return item;

      case FooterItemsEnum.STOCK_AVAILABILITY:
        item = footerItemsCheck(item, ActivityEnum.STOCK_AVAILABILITY, ProScriptConnectModule.STOCK_AVAILABILITY);
        return item;

      case FooterItemsEnum.MEDS_MANAGER:
      case FooterItemsEnum.NEW_MEDS_MANAGER_PRESCRIPTION:
        item = footerItemsCheck(item, ActivityEnum.ACCESS_REPEAT_MANAGEMENT, ProScriptConnectModule.MEDICINE_MANAGER);
        return item;

      default:
        return item;
    }
  });
};
