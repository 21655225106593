import { useContext, useState } from "react";

import { Button } from "@emisgroup/ui-button";
import AddCircle from "~icons/ic/baseline-add-circle";
import { UserDetailsContext, UserDetailsContextValue } from "../../../Contexts/UserDetailsContext/UserDetailsContext";
import ActivityEnum from "../../../Enum/Index";
import NotificationComponent, { INotificationProps } from "../../CustomComponents/NotificationComponent/Index";
import { AuthorizeWidget } from "../../AuthorizeWidget/AuthorizeWidget";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { DispatchModuleTemplateEvent, ModuleTemplateEnum } from "../../../Utilities/ModuleTemplate/Index";

export const AddButton = () => {
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);

  const HandleAddReminderClick = async () => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.ADD_REMINDER;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      return;
    }

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module);
  };
  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isNotificationOpen,
    onClose: () => setIsNotificationOpen(false)
  };
  return (
    <>
      {isNotificationOpen && <NotificationComponent {...errorNotificationProps} />}
      <div className="add-button-container">
        <Button
          data-dd-action-name="reminder-add"
          id="add-button"
          data-testid="add-button"
          borderless={true}
          onClick={() => {
            HandleAddReminderClick().catch((err) => err);
          }}
          disabled={
            !AuthorizeWidget({
              validationDetails: {
                activityId: ActivityEnum.ADD_REMINDER
              },
              contextDetails: { userDetails }
            })
          }
        >
          <AddCircle title="" data-testid="add-icon" id="add-icon" />
          Add
        </Button>
      </div>
    </>
  );
};
