import { createContext, useMemo, useState } from "react";

import ISession from "./ISession";

export type SessionContextValue = {
  session: ISession;
  setSession: (type: ISession) => void;
};

const sessionInitialValue = {
  sessionToken: undefined,
  nationalPracticeCode: undefined,
  proscriptConnectRestApiServiceUrl: undefined,
  modules: undefined,
  autoRefreshEnabled: undefined,
  autoRefreshIntervalSeconds: undefined,
  serviceDeskInfo: undefined,
  widgetOrder: undefined,
  manageWidget: undefined
};

export const SessionContext = createContext<SessionContextValue>(null);

type SessionProviderProps = {
  children: React.ReactNode;
};

const SessionProvider = (props: SessionProviderProps) => {
  const [session, setSession] = useState<ISession>(sessionInitialValue);

  const value = useMemo(
    () => ({
      session,
      setSession
    }),
    [session]
  );

  return <SessionContext.Provider value={value}>{props.children}</SessionContext.Provider>;
};

export default SessionProvider;
