enum WidgetEnum {
  REMINDERS = "Reminders",
  SCRIPTS = "Scripts",
  HANDOVER = "Handover",
  AWAITING_CLINICAL_CHECK = "Awaiting Clinical Check",
  PENDING = "Pending",
  RMS = "RMS"
}

export default WidgetEnum;
