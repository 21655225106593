const dateObj = new Date();
const currentDate = dateObj.getDate();
const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
const year = dateObj.getFullYear();
const today = `${currentDate}-${month}-${year}`;

const GetFormatedDate = (date: Date) => {
  const currentDate = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  const year = date.getFullYear().toString();
  const formatedYear = year.slice(-2);
  const formatedDate = `${currentDate} ${month} ${formatedYear}`;
  return formatedDate;
};

export { today, GetFormatedDate };
