export interface IModuleTemplate {
  id: number;
  attributes: {
    name: string;
    module: string;
  };
  type: string;
}

const pattern = (replacements: object) =>
  new RegExp(
    Object.keys(replacements)
      .map((i) => JSON.stringify(i))
      .join("|"),
    "gi"
  );

export const DispatchModuleTemplateEvent = (module: string, replacements?: object) => {
  const moduleTemplateEvent = new CustomEvent("ModuleTemplateEvent", {
    detail: {
      type: "ModuleTemplate",
      data:
        typeof replacements === "undefined"
          ? module
          : module.replace(pattern(replacements), (matched) => replacements[matched.replace(/"/g, "")])
    }
  });
  document.dispatchEvent(moduleTemplateEvent);
};
