function IsHTML(str: string) {
  const a = document.createElement("div");
  a.innerHTML = str;
  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) {
      return true;
    }
  }

  return false;
}

export const HtmlEncode = (input: string) => {
  if (IsHTML(input)) {
    const textArea = document.createElement("textarea");
    textArea.innerText = input;
    return textArea.innerHTML.split("<br>").join("\n");
  } else return input;
};
