import { useContext, useState, useEffect } from "react";

import { Button } from "@emisgroup/ui-button";
import DialogComponent, { IDialogProps } from "../CustomComponents/DialogComponent/Index";
import Config from "~icons/ic/outline-settings";
import { IModifiedWidgetItem, IWidget, WidgetContext, WidgetContextValue } from "../../Contexts/Widget/WidgetContext";
import NotificationComponent, { INotificationProps } from "../CustomComponents/NotificationComponent/Index";
import { SessionContext, SessionContextValue } from "../../Contexts/Session/SessionContext";
import { UserDetailsContext, UserDetailsContextValue } from "../../Contexts/UserDetailsContext/UserDetailsContext";
import HttpRequest, { IRequestProps } from "../../Utilities/ApiHelper/HttpRequest";
import { DialogContext, DialogContextType } from "../../Contexts/DialogContext/DialogContext";
import { DestructureArrayObject } from "../../Utilities/DestructureArrays";
import ActivityEnum from "../../Enum/ActivityEnum";
import { AuthorizeWidget } from "../AuthorizeWidget/AuthorizeWidget";
import { BannerContext, BannerContextValue, IBannerData } from "../../Contexts/BannerContext/BannerContext";
import { ModifyWidgetItemResponse } from "../../Utilities/ModifyWidgetItemResponse";

import "./ManageWidgetsButton.scss";

type ResponseType = { id: number; statusCode: number };

const ManageWidgetsButton = () => {
  const [isManageWidgetsOpen, setIsManageWidgetsOpen] = useState(false);
  const { widget, setWidget, widgetItem, setWidgetItem } = useContext<WidgetContextValue>(WidgetContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const [updatedWidgets, setUpdatedWidgets] = useState<IWidget[]>();
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);
  const [consolidatedResponse, setConsolidatedResponse] = useState<ResponseType[]>([]);
  const [apiCallCount, setApiCallCount] = useState<number>();
  const { setIsDialogOpen } = useContext<DialogContextType>(DialogContext);
  const { bannerData, setBannerData } = useContext<BannerContextValue>(BannerContext);
  const defaultBanner: IBannerData = {
    isBannerOpen: false,
    bannerType: "information",
    content: {
      title: ``,
      text: ""
    }
  };
  let updatedWidgetItems: IModifiedWidgetItem[] = widgetItem;

  useEffect(() => {
    setIsDialogOpen(isManageWidgetsOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManageWidgetsOpen]);

  useEffect(() => {
    if (apiCallCount === consolidatedResponse.length) {
      ValidateResponse(consolidatedResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedResponse?.length]);

  const HandleManageWidgetsButtonClick = () => {
    setUpdatedWidgets(DestructureArrayObject(widget));
    setIsManageWidgetsOpen(true);
  };

  const HandleManageWidgetsSave = () => {
    setConsolidatedResponse([]);
    SetModifiedWidgets().catch((err) => err);
  };

  //Method to set the modified widgets while check/uncheck item in manage widgets
  const SetModifiedWidgets = async () => {
    let modifiedWidgets = updatedWidgets.filter((item) => {
      for (let i of widget) {
        if (item.id === i.id) {
          return i.attributes.enabled !== item.attributes.enabled;
        }
      }
    });

    if (modifiedWidgets.length === 0) {
      setIsManageWidgetsOpen(false);
      return;
    }

    let statusCode: number;
    setApiCallCount(modifiedWidgets.length);
    let tempResponse: ResponseType[] = [];

    for (let item of modifiedWidgets) {
      const updateWidgetRequestProps: IRequestProps = {
        method: "PATCH",
        session,
        url: `/widgets/${item.id}`,
        data: {
          data: {
            attributes: {
              enabled: item.attributes.enabled
            },
            id: item.id,
            type: "widget"
          }
        }
      };
      await HttpRequest(updateWidgetRequestProps)
        .then((res) => {
          statusCode = res.statusCode;
          item.attributes.name === "Handover" && bannerData.bannerType == "warning" && setBannerData(defaultBanner);
        })
        .catch((err) => {
          statusCode = err.statusCode;
        });
      tempResponse = [...tempResponse, { id: item.id, statusCode }];
    }
    setConsolidatedResponse(tempResponse);
  };

  //Filter to get the partial and success widget updates from update widget call
  const ValidateResponse = (arrayItem: ResponseType[]) => {
    if (arrayItem.some((item) => item.statusCode !== 204)) {
      let successfulResponse = consolidatedResponse.filter((i) => i.statusCode === 204);
      if (successfulResponse.length === 0) {
        setIsManageWidgetsOpen(false);
        setApiCallCount(undefined);
        setIsErrorNotificationOpen(true);
        setWidget(widget);
        return;
      }
      let updatedWidgetArray = GetUpdatedValuesFromPartialSave(successfulResponse);
      setIsErrorNotificationOpen(true);
      setWidget(DestructureArrayObject(updatedWidgetArray));
    } else {
      let successfulItems = consolidatedResponse.filter((i) => i.statusCode === 204);
      GetUpdatedValuesFromSuccessfullSave(successfulItems);
      setWidget(DestructureArrayObject(updatedWidgets));
    }
    setIsManageWidgetsOpen(false);
    setApiCallCount(undefined);
  };

  const OnCheckboxStateChanged = (id: number) => {
    setUpdatedWidgets(
      updatedWidgets?.map((arr) => {
        if (arr.id === id) {
          arr.attributes.enabled = !arr.attributes.enabled;
        }
        return arr;
      })
    );
  };

  // Method to get the updated widget with items count from partaially updated scenario
  const GetUpdatedValuesFromPartialSave = (responseArray: ResponseType[]) => {
    let updatedValues = widget?.map((i) => {
      let statusCode: number;
      for (let item of responseArray) {
        if (item.id == i.id) {
          if (!i.attributes.enabled) {
            EnabledWidgetCall(item.id);
          }
          return {
            ...i,
            attributes: { ...i.attributes, enabled: statusCode == 200 ? !i.attributes.enabled : i.attributes.enabled }
          };
        } else return { ...i };
      }
    });
    return updatedValues;
  };

  // Method to get the updated widget with items count from successfully updated scenario
  const GetUpdatedValuesFromSuccessfullSave = (responseArray: ResponseType[]) => {
    for (let item of responseArray) {
      updatedWidgets.forEach((i) => {
        if (i.attributes.enabled && i.id == item.id) {
          EnabledWidgetCall(item.id);
        }
      });
    }
    return updatedWidgets;
  };

  const EnabledWidgetCall = async (id: number) => {
    let statusCode: number;
    const getWidgetsRequestProps: IRequestProps = {
      session,
      method: "GET",
      url: `/widgets/${id}?include=widgetItems`
    };
    await HttpRequest(getWidgetsRequestProps)
      .then((res) => {
        statusCode = res.statusCode;
        let updatedItems: IModifiedWidgetItem[] = widgetItem;
        updatedWidgetItems = ModifyWidgetItemResponse(res, updatedItems);
        let updatedData = updatedWidgetItems.find((i) => i.widgetId == id.toString());
        const widgetIndex = updatedItems.findIndex((obj: IModifiedWidgetItem) => Number(obj.widgetId) === Number(id));
        updatedItems[widgetIndex] = { ...updatedItems[widgetIndex], ...updatedData };
      })
      .catch((err) => {
        statusCode = err.statusCode;
        setIsErrorNotificationOpen(true);
      });
    setWidgetItem(updatedWidgetItems);
    return statusCode;
  };

  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isErrorNotificationOpen,
    onClose: () => setIsErrorNotificationOpen(false)
  };

  const dialogProps: IDialogProps = {
    dialogHeader: "Manage Widgets",
    dialogContent: "Select the widgets to be shown in the home screen",
    isDialogOpen: isManageWidgetsOpen,
    setIsDialogOpen: setIsManageWidgetsOpen,
    dialogCheckboxContent: updatedWidgets,
    handleSaveButtonClick: HandleManageWidgetsSave,
    onCheckboxStateChanged: OnCheckboxStateChanged,
    dialogFooterButtonSaveText: "Save"
  };

  return (
    <div data-testid="manage-button-test">
      {AuthorizeWidget({
        validationDetails: {
          activityId: ActivityEnum.MANAGE_WIDGET
        },
        contextDetails: { userDetails }
      }) &&
        widget !== undefined &&
        widget?.length !== 0 && (
          <Button
            data-dd-action-name="manage-widgets"
            borderless={true}
            className="manage-widgets"
            onClick={HandleManageWidgetsButtonClick}
          >
            <Config title="" data-testid="config-icon" className="manage-widget-icon" />
            Manage Widgets
          </Button>
        )}
      {isErrorNotificationOpen && <NotificationComponent {...errorNotificationProps} />}
      {isManageWidgetsOpen && <DialogComponent {...dialogProps} />}
    </div>
  );
};

export default ManageWidgetsButton;
