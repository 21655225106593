import { useState } from "react";

import WidgetProvider from "../../Contexts/Widget/WidgetContext";
import TopBar from "../TopBar/Index";
import WidgetCollection from "../WidgetCollection/Index";
import { DialogProvider } from "../../Contexts/DialogContext/DialogContext";
import { BannerProvider } from "../../Contexts/BannerContext/BannerContext";
import { Footer } from "../Footer/Footer";
import ModuleTemplateProvider from "../../Contexts/ModuleTemplate/ModuleTemplate";

export const Home = () => {
  const [widgetEnable, setWidgetEnable] = useState(false);

  return (
    <div className="home-container" data-testid="home">
      <div>
        <DialogProvider>
          <BannerProvider>
            <WidgetProvider>
              <ModuleTemplateProvider>
                <TopBar widgetEnable={widgetEnable} />
                <WidgetCollection setWidgetEnable={setWidgetEnable} />
                <Footer />
              </ModuleTemplateProvider>
            </WidgetProvider>
          </BannerProvider>
        </DialogProvider>
      </div>
    </div>
  );
};
