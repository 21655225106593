import { useContext, useEffect, useState } from "react";

import DialogComponent, { IDialogProps } from "../CustomComponents/DialogComponent/Index";
import Config from "~icons/ic/outline-settings";
import { IWidget, IModifiedWidgetItem, WidgetContext, WidgetContextValue } from "../../Contexts/Widget/WidgetContext";
import { UserDetailsContext, UserDetailsContextValue } from "../../Contexts/UserDetailsContext/UserDetailsContext";
import HttpRequest, { IRequestProps } from "../../Utilities/ApiHelper/HttpRequest";
import { SessionContext, SessionContextValue } from "../../Contexts/Session/SessionContext";
import NotificationComponent, { INotificationProps } from "../CustomComponents/NotificationComponent/Index";
import { DestructureArrayObject } from "../../Utilities/DestructureArrays";
import { DialogContext, DialogContextType } from "../../Contexts/DialogContext/DialogContext";
import ActivityEnum from "../../Enum/ActivityEnum";
import { AuthorizeWidget } from "../AuthorizeWidget/AuthorizeWidget";

type PatchResponseType = { id: number; statusCode: number; widgetItem: IWidget };

const ManageWidgetItems = ({ widgetItems, widgetName }) => {
  // Variables from context
  const { widgetItem, setWidgetItem } = useContext<WidgetContextValue>(WidgetContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { setIsDialogOpen } = useContext<DialogContextType>(DialogContext);
  // Variables to hold state changes and copy of context values
  const [updatedWidgetItems, setUpdatedWidgetItems] = useState<IWidget[]>();
  const [currentWidget, setCurrentWidget] = useState(undefined);
  const [currentWidgetItem, setCurrentWidgetItem] = useState(undefined);
  const [widgetItemCopy, setWidgetItemCopy] = useState(undefined);
  // Variables to hold API related data
  const [consolidatedResponse, setConsolidatedResponse] = useState<PatchResponseType[]>([]);
  const [apiCallCount, setApiCallCount] = useState<number>();
  // Variables for other components
  const [isManageWidgetItemsOpen, setIsManageWidgetItemsOpen] = useState(false);
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);

  useEffect(() => {
    if (apiCallCount === consolidatedResponse.length) {
      ValidateResponse(consolidatedResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedResponse?.length]);

  useEffect(() => {
    setIsDialogOpen(isManageWidgetItemsOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManageWidgetItemsOpen]);

  const HandleManageWidgetItems = () => {
    setUpdatedWidgetItems(DestructureArrayObject(widgetItems));
    setIsManageWidgetItemsOpen(true);
    let localCurrentWidget = widgetItem?.find((i) => i.widgetName === widgetName);
    setCurrentWidget(localCurrentWidget);
    setCurrentWidgetItem(localCurrentWidget?.widgetItem);
    setWidgetItemCopy(DestructureArrayObject(widgetItem));
  };

  const HandleManageWidgetItemsSave = () => {
    setConsolidatedResponse([]);
    SetModifiedWidgetItems().catch((err) => err);
  };

  const OnCheckboxStateChanged = (id: number) => {
    setUpdatedWidgetItems(
      updatedWidgetItems?.map((arr) => {
        if (arr.id === id) {
          arr.attributes.enabled = !arr.attributes.enabled;
        }
        return arr;
      })
    );
  };

  const SetModifiedWidgetItems = async () => {
    let modifiedWidgetItems = updatedWidgetItems.filter((item) => {
      for (let i of currentWidgetItem) {
        if (item.id === i.id) {
          return i.attributes.enabled !== item.attributes.enabled;
        }
      }
    });

    if (modifiedWidgetItems.length === 0) {
      setIsManageWidgetItemsOpen(false);
      return;
    }

    let statusCode: number;
    let responseWidgetItem: IWidget;
    let tempResponse: PatchResponseType[] = [];
    setApiCallCount(modifiedWidgetItems.length);

    for (let item of modifiedWidgetItems) {
      const updateWidgetRequestProps: IRequestProps = {
        method: "PATCH",
        session,
        url: `/widgets/${currentWidget.widgetId}/widgetItems/${item.id}`,
        data: {
          data: {
            attributes: {
              enabled: item.attributes.enabled
            },
            id: item.id,
            type: "widget-item"
          }
        }
      };

      await HttpRequest(updateWidgetRequestProps)
        .then((res) => {
          responseWidgetItem = res.data;
          statusCode = res.statusCode;
        })
        .catch((err) => {
          statusCode = err.statusCode;
          responseWidgetItem = err.data;
        });
      tempResponse = [...tempResponse, { id: item.id, statusCode, widgetItem: responseWidgetItem }];
    }

    setConsolidatedResponse(tempResponse);
  };

  //Validate the consolidated response returned from the looped api call response
  const ValidateResponse = (arrayItem: PatchResponseType[]) => {
    if (arrayItem.some((item) => item.statusCode !== 200)) {
      let successfulResponse = arrayItem.filter((i) => i.statusCode === 200);

      if (successfulResponse.length === 0) {
        setIsManageWidgetItemsOpen(false);
        setApiCallCount(undefined);
        setIsErrorNotificationOpen(true);
        return;
      }

      setWidgetItem(DestructureArrayObject(UpdateWidgetItems()));
      setIsErrorNotificationOpen(true);
    } else {
      setWidgetItem(DestructureArrayObject(UpdateWidgetItems()));
    }

    setIsManageWidgetItemsOpen(false);
    setApiCallCount(undefined);
  };

  //Returns the widget items specific to widget with the updated attributes based on api response
  const UpdateWidgetItems = () => {
    const updatedItems: IModifiedWidgetItem[] = widgetItemCopy.map((item) => {
      if (item?.widgetName !== widgetName) return item;

      let newWidgetItem: IModifiedWidgetItem[];
      newWidgetItem = item.widgetItem?.map((i) => {
        let currentResponse = consolidatedResponse.find((ele) => ele.id === i.id);

        if (currentResponse?.statusCode === 200) return currentResponse.widgetItem;
        else return i;
      });
      return { ...item, widgetItem: newWidgetItem };
    });
    return updatedItems;
  };

  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isErrorNotificationOpen,
    onClose: () => setIsErrorNotificationOpen(false)
  };

  const dialogProps: IDialogProps = {
    dialogHeader: "Manage Widget: " + widgetName,
    dialogContent: "Select the items to be shown in this widget",
    isDialogOpen: isManageWidgetItemsOpen,
    setIsDialogOpen: setIsManageWidgetItemsOpen,
    dialogCheckboxContent: updatedWidgetItems,
    handleSaveButtonClick: HandleManageWidgetItemsSave,
    onCheckboxStateChanged: OnCheckboxStateChanged,
    dialogFooterButtonSaveText: "Save"
  };

  return (
    <div>
      {isErrorNotificationOpen && <NotificationComponent {...errorNotificationProps} />}
      {AuthorizeWidget({
        validationDetails: {
          activityId: ActivityEnum.MANAGE_WIDGET
        },
        contextDetails: { userDetails }
      }) &&
        widgetItems?.length !== 0 && (
          <button
            data-dd-action-name="manage-widget-items"
            className="widget-header-settings-button"
            onClick={HandleManageWidgetItems}
          >
            <Config title="" data-testid="manage-widget-items-icon" className="widget-header-settings-icon"></Config>
          </button>
        )}
      {isManageWidgetItemsOpen && <DialogComponent {...dialogProps} />}
    </div>
  );
};

export default ManageWidgetItems;
