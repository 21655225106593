import { useContext } from "react";

import { IWidget, WidgetContext, WidgetContextValue } from "../Contexts/Widget/WidgetContext";
import ActivityEnum from "../Enum/Index";
import WidgetEnum from "../Enum/WidgetEnum";
import ProScriptConnectModule from "../Enum/ProScriptConnectModule";
import { AuthorizeWidget } from "../Components/AuthorizeWidget/AuthorizeWidget";
import { UserDetailsContext, UserDetailsContextValue } from "../Contexts/UserDetailsContext/UserDetailsContext";
import { SessionContext, SessionContextValue } from "../Contexts/Session/SessionContext";

interface IVerifyWidgetProps {
  name: string;
  refreshIntervalSecond: number;
  enabled: boolean;
}

const useVerifyWidget = (): IVerifyWidgetProps[] => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const { widget, setWidget } = useContext<WidgetContextValue>(WidgetContext);
  const enabledWidgets: IVerifyWidgetProps[] = [];

  widget?.forEach((widgets) => {
    switch (widgets.attributes.name) {
      case WidgetEnum.REMINDERS:
        {
          const isReminderEnabled = AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.VIEW_REMINDER,
              moduleName: [ProScriptConnectModule.REMINDERS]
            },
            contextDetails: { widget, userDetails, session }
          });

          if (isReminderEnabled)
            enabledWidgets.push({
              name: WidgetEnum.REMINDERS,
              refreshIntervalSecond: widgets.attributes.refreshIntervalSecond,
              enabled: widgets.attributes.enabled
            });
          else {
            setWidget((widgetData: IWidget[]): IWidget[] =>
              widgetData.filter((obj: IWidget) => obj.attributes.name !== WidgetEnum.REMINDERS)
            );
          }
        }
        break;

      case WidgetEnum.SCRIPTS:
        enabledWidgets.push({
          name: WidgetEnum.SCRIPTS,
          refreshIntervalSecond: widgets.attributes.refreshIntervalSecond,
          enabled: widgets.attributes.enabled
        });

        break;

      case WidgetEnum.HANDOVER:
        enabledWidgets.push({
          name: WidgetEnum.HANDOVER,
          refreshIntervalSecond: widgets.attributes.refreshIntervalSecond,
          enabled: widgets.attributes.enabled
        });

        break;

      case WidgetEnum.AWAITING_CLINICAL_CHECK:
        enabledWidgets.push({
          name: WidgetEnum.AWAITING_CLINICAL_CHECK,
          refreshIntervalSecond: widgets.attributes.refreshIntervalSecond,
          enabled: widgets.attributes.enabled
        });

        break;

      case WidgetEnum.RMS:
        {
          const isRmsEnabled = AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_REPEAT_MANAGEMENT,
              moduleName: [ProScriptConnectModule.RMS]
            },
            contextDetails: { widget, userDetails, session }
          });

          if (isRmsEnabled)
            enabledWidgets.push({
              name: WidgetEnum.RMS,
              refreshIntervalSecond: widgets.attributes.refreshIntervalSecond,
              enabled: widgets.attributes.enabled
            });
          else {
            setWidget((widgetData: IWidget[]): IWidget[] =>
              widgetData.filter((obj: IWidget) => obj.attributes.name !== WidgetEnum.RMS)
            );
          }
        }
        break;

      case WidgetEnum.PENDING:
        enabledWidgets.push({
          name: WidgetEnum.PENDING,
          refreshIntervalSecond: widgets.attributes.refreshIntervalSecond,
          enabled: widgets.attributes.enabled
        });

        break;
    }
  });

  return enabledWidgets;
};

export default useVerifyWidget;
