enum FooterItemsEnum {
  NMS = "NMS",
  PRESCRIPTION_AND_ITEM_PROCESSED = "Prescription and Item Processed Today",
  OWING = "Owings",
  DORMANT_OWING = "Dormant Owings",
  EMERGENCY_SUPPLY_OVERDUE = "Emergency Supply Overdue",
  REPEAT_PRIVATE_PRESCRIPTION = "Dispensed Repeat Private Prescription",
  MDS = "MDS Prep Items",
  MEDS_MANAGER = "MedsManager Rejected Requests",
  NEW_MEDS_MANAGER_PRESCRIPTION = "New MedsManager Prescription",
  STOCK_AVAILABILITY = "StockAvailability",
  PROSCRIPT_CONNECT_SERVICE_DESK_NUMBER = "ProScript Connect Service Desk Number"
}

export default FooterItemsEnum;
