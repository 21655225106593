import { useState, useEffect, useContext } from "react";

import { Input } from "@emisgroup/ui-input";
import PatientSearch from "~icons/ic/outline-person-search";
import { useDebounce } from "../../../CustomHooks/UseDebounce";
import NotificationComponent, { INotificationProps } from "../../CustomComponents/NotificationComponent/Index";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { DispatchModuleTemplateEvent } from "../../../Utilities/ModuleTemplate/Index";

import "../SearchBar.scss";

const PatientSearchBar = () => {
  const [searchTextValue, setSearchTextValue] = useState("");
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const debouncedValue = useDebounce<string>(searchTextValue, 500);
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);

  useEffect(() => {
    document.getElementById("txt-patient-search").focus();
  }, []);

  useEffect(() => {
    TriggerPatientSearchModule(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const HandlePatientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextValue(e.target.value);
  };

  const TriggerPatientSearchModule = (value: string) => {
    if (value.length === 0) return;

    //To check if the type value has only empty space entirely
    let valueArray: string[] = [];

    for (let item of value) {
      valueArray = [...valueArray, item];
    }

    if (valueArray.every((i) => i == " ")) return;

    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.PATIENT_SEARCH;
    });

    if (selectedTemplate == null) {
      setIsErrorNotificationOpen(true);
      return;
    }

    let searchReplacement = {
      SearchText: JSON.stringify(value.trimEnd().trimStart())
    };

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module, searchReplacement);
    setSearchTextValue("");
  };

  const NotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isErrorNotificationOpen,
    onClose: () => setIsErrorNotificationOpen(false)
  };

  return (
    <>
      {isErrorNotificationOpen && <NotificationComponent {...NotificationProps} />}
      <Input
        data-dd-action-name="patient-search"
        clearable={false}
        aria-label="with-icon"
        className="search-bar"
        id="txt-patient-search"
        data-testid="txt-patient-search"
        type="text"
        value={searchTextValue}
        placeholder="Search patient"
        onChange={(e) => HandlePatientSearch(e)}
      >
        <PatientSearch title="" className="search-bar-icon" />
      </Input>
    </>
  );
};

export default PatientSearchBar;
