enum WidgetOrderEnum {
  "Scripts",
  "Reminders",
  "Handover",
  "Pending",
  "Awaiting Clinical Check",
  "RMS"
}

export default WidgetOrderEnum;
