import axios, { AxiosInstance } from "axios";

import ISession from "../../Contexts/Session/ISession";

export type IResponseType = {
  statusCode: number;
  data: any;
  included?: any;
};

export type IRequestProps = {
  method: "GET" | "POST" | "PATCH" | "PUT";
  url: string;
  session: ISession;
  params?: object;
  data?: object;
  headers?: object;
};

const createApiResponse = (statusCode: number, data: any, included?: any): IResponseType => ({
  statusCode: statusCode,
  data: data,
  included: included
});

const HttpRequest = async (props: IRequestProps): Promise<IResponseType> => {
  const headers = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    NACSCode: props.session.nationalPracticeCode,
    SessionToken: props.session.sessionToken
  };

  if (props.url.includes("reminders") || props.url.includes("widgets") || props.url.includes("footerItems")) {
    props.headers = { Modules: props.session.modules.toString() };
  }

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: props.session.proscriptConnectRestApiServiceUrl,
    headers: headers,
    timeout: 30000
  });

  try {
    if (props.method === "POST" && props.data === undefined) {
      return Promise.reject(createApiResponse(500, "POST request must have data"));
    }

    const response = await axiosInstance.request({
      method: props.method,
      url: props.url,
      params: props.params ?? {},
      data: props.data ?? {},
      headers: props.headers ?? {}
    });

    return Promise.resolve(createApiResponse(response.status, response.data.data, response.data?.included));
  } catch (error) {
    return Promise.reject(
      error.response
        ? createApiResponse(error.response.status, error.response.data.errors)
        : createApiResponse(500, error.message)
    );
  }
};

export default HttpRequest;
