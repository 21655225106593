import { useContext, useEffect, useState } from "react";

import {
  IModifiedWidgetItem,
  IWidgetItems,
  WidgetContext,
  WidgetContextValue
} from "../../../Contexts/Widget/WidgetContext";
import { Combobox, Key } from "@emisgroup/ui-combobox";
import Search from "~icons/ic/outline-search";
import useVerifyWidget from "../../../CustomHooks/UseVerifyWidget";
import { SortWidgetItems } from "../../../Utilities/SortWidgetItems";

import "../SearchBar.scss";

const WidgetSearchBar = () => {
  const [searchWidgetValue, setSearchWidgetValue] = useState("");
  const { widget, widgetItem, setSearchWidgetText } = useContext<WidgetContextValue>(WidgetContext);
  const widgetEnable = useVerifyWidget();
  const [isEmpty, setIsEmpty] = useState(false);
  const [searchResultValues, setSearchResultValues] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState<Key>();

  const modifiedWidget: string[] = widget.map((item) => {
    if (item.attributes.enabled) {
      return item.attributes.name;
    }
  });

  const modifiedWidgetItem: IModifiedWidgetItem[] = widgetItem.filter((item) => {
    if (modifiedWidget.includes(item.widgetName)) {
      return item;
    }
  });

  const enabledWidgets: string[] = widgetEnable.map((widget) => widget.name);

  const filteredWidgetItems: IModifiedWidgetItem[] = modifiedWidgetItem
    .filter((item: IModifiedWidgetItem) => enabledWidgets.includes(item.widgetName) && item.widgetEnabled)
    .map((widget: IModifiedWidgetItem) => ({
      ...widget,
      widgetItem: widget.widgetItem
        .filter((item) => item.attributes.enabled && "count" in item.attributes)
        .sort((currentValue, nextValue) => SortWidgetItems(currentValue, nextValue))
    }));

  const HandleWidgetSearch = (e: Key) => {
    if (e) {
      setSearchWidgetText(e.toString().split("|")[0].toLowerCase());
      setSearchWidgetValue(e.toString().split("|")[0].toLowerCase());
      setSelectedKey(e);
      HandleWidgetSearchSuggestions(e.toString());
    } else if (!e && searchWidgetValue == "") {
      setSearchWidgetText("");
      setSearchWidgetValue("");
      setSelectedKey("");
      HandleWidgetSearchSuggestions("");
    }
  };

  const HandleWidgetSearchSuggestions = (searchText: string) => {
    let resultArray: string[] = [];

    if (!searchText.split("|")[0].length) {
      setIsEmpty(false);

      filteredWidgetItems.map((widget) => {
        resultArray.push(widget.widgetName);
        widget.widgetItem.map((item) => {
          resultArray.push(item.attributes.name);
        });
        setSelectedKey(undefined);
      });
    } else {
      filteredWidgetItems.map((widget) => {
        if (widget.widgetName.toLowerCase().includes(searchText?.toLowerCase())) {
          resultArray.push(widget.widgetName);
          setIsEmpty(false);
        }

        widget.widgetItem?.map((item: IWidgetItems) => {
          if (item.attributes.name.toLowerCase().includes(searchText?.toLowerCase())) {
            if (resultArray.includes(widget.widgetName) || resultArray.includes(widget.widgetName + "|" + searchText)) {
              resultArray.push(item.attributes.name);
            } else {
              resultArray.push(widget.widgetName + "|" + searchText);
              resultArray.push(item.attributes.name);
            }
            setIsEmpty(false);
          }
        });
      });
    }

    if (!resultArray.length && !searchText.includes("|")) {
      setIsEmpty(true);
    }

    setSearchResultValues(resultArray);
    setSearchWidgetValue(searchText.split("|")[0]);
  };

  const HighlightText = (text: string) => {
    if (!searchWidgetValue) return text;

    const escapeRegExp = (texts: string) => texts.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const escapedValue = escapeRegExp(searchWidgetValue);
    const regex = new RegExp(`(${escapedValue})`, "gi");
    const parts = text.split(regex);

    return parts.map((part: string, index: number) => {
      return part.toLowerCase() === searchWidgetValue?.toLowerCase() ? (
        <strong key={`${part.toLocaleLowerCase()}_${index}`}>{part}</strong>
      ) : (
        part
      );
    });
  };

  useEffect(() => {
    HandleWidgetSearchSuggestions(searchWidgetValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, widgetItem]);

  const HandleOnClear = () => {
    setSearchWidgetText("");
    setSelectedKey("");
    setSearchWidgetValue("");
    HandleWidgetSearchSuggestions("");
    setIsEmpty(false);
  };

  return (
    <div className="widget-search-bar" data-testid="widget-search-bar">
      <Combobox
        data-dd-action-name="widget-search"
        showButton={false}
        aria-label="Combobox"
        id="txt-widget-search"
        data-testid="txt-widget-search"
        inputValue={searchWidgetValue}
        placeholder="Search widgets"
        onInputChange={(e) => HandleWidgetSearchSuggestions(e)}
        onClear={() => HandleOnClear()}
        onSelectionChange={(key) => HandleWidgetSearch(key)}
        menuTrigger="focus"
        clearable={true}
        selectedKey={selectedKey}
        icon={<Search size="medium" title="" className="search-bar-icon"></Search>}
      >
        {searchResultValues.map((item) =>
          item.includes("|") ? (
            <Combobox.Item key={item} textValue={item}>
              <span data-testid={item.split("|")[0]}>{HighlightText(item.split("|")[0])}</span>
            </Combobox.Item>
          ) : (
            <Combobox.Item key={item} textValue={item}>
              <span data-testid={item} className={enabledWidgets.includes(item) ? "" : "search-widget-item"}>
                {HighlightText(item)}
              </span>
            </Combobox.Item>
          )
        )}
      </Combobox>
      {isEmpty && !searchResultValues.includes(searchWidgetValue) && (
        <span className="search-no-results" data-testid="search-no-results">
          No Results Found
        </span>
      )}
    </div>
  );
};

export default WidgetSearchBar;
