import IUserDetails from "../../Contexts/UserDetailsContext/IUserDetails";
import ISession from "../../Contexts/Session/ISession";
import { IWidget } from "../../Contexts/Widget/WidgetContext";

interface IAuthorizeCardProps {
  activityId?: number;
  moduleName?: string[];
  widgetName?: string;
}

interface IContextProps {
  userDetails?: IUserDetails;
  session?: ISession;
  widget?: IWidget[];
}

interface IAuthorizeWidgetProps {
  validationDetails: IAuthorizeCardProps;
  contextDetails: IContextProps;
}

export const AuthorizeWidget = ({ validationDetails, contextDetails }: IAuthorizeWidgetProps): boolean => {
  if (
    (validationDetails.activityId && !FilterActivity(validationDetails.activityId, contextDetails.userDetails)) ||
    (validationDetails.moduleName && !FilterModules(validationDetails.moduleName, contextDetails.session))
  ) {
    return false;
  }

  return true;
};

const FilterModules = (moduleName: string[], session: ISession): boolean =>
  moduleName.some((module) => session.modules.includes(module));

const FilterActivity = (activity: number, userDetails: IUserDetails): boolean =>
  userDetails?.activities?.includes(activity);
