import { Button } from "@emisgroup/ui-button";
import { Notification, NotificationContainer } from "@emisgroup/ui-notification";
import Close from "~icons/ic/baseline-close";
import InfoConfirm from "~icons/ic/baseline-check-circle";
import ErrorCircle from "~icons/ic/outline-error";

import "@emisgroup/ui-styles/dist/base.css";
import "./NotificationComponent.scss";

export type NotificationType = "confirmation" | "info" | "error";

export interface INotificationContentProps {
  title: string;
  text?: string;
}

export interface INotificationProps {
  content?: INotificationContentProps;
  notificationType: "confirmation" | "info" | "error";
  onClose: () => void;
  isOpen: boolean;
}

export const NotificationContent = {
  default: { title: "", text: "" },
  error: { title: "Error processing request.", text: "Please try again" }
};

const NotificationComponent = (props: INotificationProps) => {
  return (
    <NotificationContainer id="notification-container" data-testid="notifications">
      <Notification
        id={props.notificationType == "error" ? "error-notification" : "success-notification"}
        open={props.isOpen}
        duration={3000}
        onClose={props.onClose}
      >
        <div>
          {props.notificationType == "error" ? (
            <ErrorCircle className="error-notification-title" title="Success" />
          ) : (
            <InfoConfirm className="success-notification-title" title="Success" />
          )}
        </div>
        <div>
          <Notification.Title>
            {props.notificationType == "error" ? (
              <>
                <strong className="error-notification-title">{NotificationContent.error.title}</strong>
                <span>{NotificationContent.error.text}</span>
              </>
            ) : (
              <>
                <strong className="success-notification-title">{props.content.title}</strong>
                <span>{props.content.text}</span>
              </>
            )}
          </Notification.Title>
        </div>
        <Notification.Close asChild={true}>
          <Button className="notification-close-button" borderless={true} data-testid="notification-close-button">
            <Close title="Close"></Close>
          </Button>
        </Notification.Close>
      </Notification>
    </NotificationContainer>
  );
};

export default NotificationComponent;
