import { IModifiedWidgetItem } from "../Contexts/Widget/WidgetContext";
import { IResponseType } from "./ApiHelper/HttpRequest";

export const ModifyWidgetItemResponse = (response: IResponseType, widgetItem: IModifiedWidgetItem[]) => {
  let modifiedWidgetItem: IModifiedWidgetItem[] = widgetItem.map((item) => {
    if (item.widgetId != response.data.id) return item;
    let relationshipsId = response.data?.relationships.widgetItems.data.map((i) => i.id);
    let objectValue = {
      widgetName: response?.data?.attributes.name,
      widgetEnabled: response?.data?.attributes.enabled,
      widgetId: response?.data?.id,
      relationshipsId: relationshipsId,
      widgetItem: response.included || []
    };
    return { ...objectValue };
  }, []);
  return modifiedWidgetItem;
};
