import React from "react";

import { FeedItemProps } from "./Footer";

const FeedItems: React.FC<FeedItemProps> = (props) => {
  const { items, listItemOnClick } = props;
  const HandleClickEvent = (name: string) => {
    listItemOnClick(name);
  };

  return (
    <>
      {items?.map(({ attributes }, index) => (
        <div
          data-dd-action-name={attributes.hyperlinkEnabled ? "footer-" + attributes.modulename : "no-action-text"}
          data-testid={`feed-item-${index}`}
          className="feed-list-item-content"
          key={attributes.modulename}
          id={`list-${index}`}
        >
          {attributes.hyperlinkEnabled ? (
            <input
              className={`feed-list-item-text hyperlink-text ${attributes.color.toLowerCase()}`}
              data-testid={`clicked-item-${index}`}
              type="button"
              value={attributes.description}
              tabIndex={0}
              onClick={() => HandleClickEvent(attributes.modulename)}
            ></input>
          ) : (
            <span className={`feed-list-item-text ${attributes.color.toLowerCase()}`}>
              {attributes.description.split("\n").map((desc) => {
                return <div key={desc}>{desc}</div>;
              })}
            </span>
          )}
        </div>
      ))}
    </>
  );
};
export default FeedItems;
